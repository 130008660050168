<template>
  <div>
    <div class="home" :class="isPc ? '' : 'home-mobile'">
      <div>
        <NavM v-if="!isPc"></NavM>
        <div class="home-header" v-if="isPc">
          <div class="home-header-title">
            {{ $t('home.homeTitle') }}
          </div>
          <div class="home-header-app">
            <div class="app-icon">
              <img src="@/assets/img/app_store.f539e414.svg" alt="" srcset="" />
            </div>
            <div class="app-right">
              <div class="app-ava">Available on the</div>
              <div class="app-store">App Store</div>
            </div>
          </div>
          <div class="home-header-application">
            <div class="icon-title">
              <img src="@/assets/img/icon_quadrangle.b1234c66.svg" alt="" /><span>{{
                $t('home.Application') }}</span>
            </div>
            <div class="icon-content">
              {{ $t('home.appLicationInfo') }}
            </div>
          </div>
          <div class="home-header-credit">
            <div class="icon-title">
              <img src="@/assets/img/icon_quadrangle.b1234c66.svg" alt="" /><span>{{
                $t('home.CreditCard') }}</span>
            </div>
            <div class="icon-content">
              {{ $t('home.CreditCardInfo') }}
            </div>
          </div>
          <div class="home-header-box">
            <div class="box-money">
              <div class="box-top">$3.8 B</div>
              <div class="box-content">{{ $t('home.billionInfo') }}</div>
            </div>
            <div class="box-times">
              <div class="box-top">350+</div>
              <div class="box-content"> {{ $t('home.CryptocurrenciesListed') }}</div>
            </div>
            <div class="box-mill">
              <div class="box-top">1.2 M</div>
              <div class="box-content">{{ $t('home.RegisteredUsers') }}</div>
            </div>
          </div>
          <div class="home-header-imgs">
            <div class="imgs-left">
              <img src="@/assets/img/home_iphone1.b28f3fe0.svg" alt="" />
            </div>
            <div class="imgs-right">
              <img src="@/assets/img/home_iphone2.da1b154c.svg" alt="" />
            </div>
          </div>
        </div>
        <div class="home-body" v-if="isPc">
          <div class="box-butttons1">
            <div class="buttons-big buttons-big2 clicek-points" @click="$router.push('/help/notice')">
              <div class="info-buttons-content">
                <div class="content-icon"></div>
                <div class="content-text">{{ $t('home.NewFeaturesInOnline') }}</div>
                <div class="content-text1">
                  <p>
                    {{ $t('home.NewFeaturesInOnlineInfo') }}
                  </p>
                </div>
              </div>
              <div class="masking"></div>
            </div>
            <div class="buttons-big buttons-big1 clicek-points" @click="$router.push('/profile')">
              <div class="content-icon"></div>
              <div class="content-text">
                <span>{{ $t('home.noticeInfo1') }}
                </span>
              </div>
            </div>
          </div>
          <div class="box-butttons2">
            <div class="butttons2">
              <div class="info-buttons-content">
                <div class="butttons2-icon">
                  <img src="@/assets/img/icon_place.201127c8.svg" alt="" />
                </div>
                <div class="butttons2-text1">
                  {{ $t('home.button1Title') }}&lt; 0.3% 
                </div>
                <div class="butttons2-text2">
                  {{ $t('home.button1Title1') }}

                </div>
                <div class="butttons2-text3">
                  {{ $t('home.button1Title2') }}

                </div>
                <div class="butttons2-button clicek-points" @click="$router.push('/market')">
                  {{ $t('home.GetStarted') }}

                </div>
              </div>
              <div class="masking"></div>
            </div>
            <div class="butttons2">
              <div class="info-buttons-content">
                <div class="butttons2-icon">
                  <img src="@/assets/img/icon_loans.dd791035.svg" alt="" />
                </div>
                <div class="butttons2-text1">
                  {{ $t('home.button2Title') }}
                </div>
                <div class="butttons2-text2"> {{ $t('home.button2Title1') }}
                </div>
                <div class="butttons2-text3">
                  {{ $t('home.button2Title2') }}

                </div>
                <div class="butttons2-button clicek-points" @click="$router.push('/loan')">
                  {{ $t('home.GetStarted') }}
                </div>
              </div>
              <div class="masking"></div>
            </div>
            <div class="butttons2">
              <div class="info-buttons-content">
                <div class="butttons2-icon">
                  <img src="@/assets/img/icon_trading.733e50e8.svg" alt="" />
                </div>
                <div class="butttons2-text1"> {{ $t('home.button3Title') }}
                </div>
                <div class="butttons2-text2"> {{ $t('home.button3Title1') }}
                </div>
                <div class="butttons2-text3">
                  {{ $t('home.button3Title2') }}
                </div>
                <div class="butttons2-button clicek-points" @click="$router.push('/trading')">
                  {{ $t('home.GetStarted') }}
                </div>
              </div>
              <div class="masking"></div>
            </div>
            <div class="butttons2">
              <div class="info-buttons-content">
                <div class="butttons2-icon butttons2-icon1">
                  <img src="@/assets/img/icon_charity.ce0fa0ca.svg" alt="" />
                </div>
                <div class="butttons2-text1">
                  {{ $t('home.button4Title') }}

                </div>
                <div class="butttons2-text2"> {{ $t('home.button4Title1') }}
                </div>
                <div class="butttons2-text3">
                  {{ $t('home.button4Title2') }}

                </div>
                <div class="butttons2-button clicek-points" @click="$router.push('/nfts')">
                  {{ $t('home.GetStarted') }}

                </div>
              </div>
              <div class="masking"></div>
            </div>
          </div>
          <div>
            <div class="box-body">
              <div class="box-lists">
                <div class="box-heder">
                  <div class="box-heder1">{{ $t('Market.name') }}</div>
                  <div class="box-heder2">24h%</div>
                  <div class="box-heder3">{{ $t('Market.chart') }}
                  </div>
                  <div class="box-heder4">{{ $t('Market.Price') }}</div>
                </div>
                <div class="box-body">
                  <div class="box-list" @click="toDetail(item)" style="z-index: 10;" v-for="(item, index) in marketList"
                    :key="index">
                    <div class="box-heder1"><img :src="item.logo" alt="" srcset="">
                      {{ item.appName }} </div>
                    <div class="box-heder2" :class="{ 'box-heder2-1': item.zdf > 0 }">{{ item.zdf }}%</div>
                    <div class="box-heder3">
                      <div id="chart2" class="chart"
                        style="user-select: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0);"
                        _echarts_instance_="ec_1700723092218">
                        <div :id="item.code"
                          style="position: relative; width: 180px; height: 60px; padding: 0px; margin: 0px; border-width: 0px;">
                          <!-- <canvas data-zr-dom-id="zr_0" width="360" height="120"
                            style="position: absolute; left: 0px; top: 0px; width: 180px; height: 60px; user-select: none; -webkit-tap-highlight-color: rgba(0, 0, 0, 0); padding: 0px; margin: 0px; border-width: 0px;"></canvas> -->
                        </div>
                      </div>
                    </div>
                    <div class="box-heder4">{{ item.nowPrice }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="content"  v-if="!isPc">
          <div class="new-feature">
            <div class="new-feature-content" 
            @click="$router.push('/help/notice')"
            >
              <div class="feature-icon"><img src="@/assets/img/icon_vetor.e4607d5d.svg" alt="" srcset=""></div>
              <div class="feature-title">{{ $t('home.NewFeaturesInOnline') }} </div>
              <div class="feature-content">
                <p>{{ $t('home.NewFeaturesInOnlineInfo') }}</p>
              </div>
            </div>
            <div class="masking"></div>
          </div>
          <div class="wallet" 
          @click="$router.push('/profile')"
          ><img src="@/assets/img/icon_bell1.98a9a1d5.svg" alt="" srcset="">
            <div>{{ $t('home.noticeInfo1') }}</div>
          </div>
          <div class="info-buttons">
            <div class="info-buttons-content">
              <div class="null-height"></div>
              <div class="icon"><img src="@/assets/img/icon_place.201127c8.svg" alt=""></div>
              <div class="info-text1">{{ $t('home.button1Title') }} &lt; 0.3% </div>
              <div class="info-text2">{{ $t('home.button1Title1') }}</div>
              <div class="info-text3">{{ $t('home.button1Title2') }}</div>
              <div class="info-button"
                @click="$router.push('/market')">{{ $t('home.GetStarted') }}</div>
            </div>
            <div class="masking"></div>
          </div>
          <div class="info-buttons">
            <div class="info-buttons-content">
              <div class="null-height"></div>
              <div class="icon"><img src="@/assets/img/icon_trading.733e50e8.svg" alt=""></div>
              <div class="info-text1">{{ $t('home.button3Title') }}</div>
              <div class="info-text2">{{ $t('home.button3Title1') }}</div>
              <div class="info-text3">{{ $t('home.button3Title2') }}</div>
              <div class="info-button"
                @click="$router.push('/trading')"
              >{{ $t('home.GetStarted') }}</div>
            </div>
            <div class="masking"></div>
          </div>
          <div class="info-buttons">
            <div class="info-buttons-content">
              <div class="null-height"></div>
              <div class="icon"><img src="@/assets/img/icon_loans.dd791035.svg" alt=""></div>
              <div class="info-text1">{{ $t('home.button2Title') }}</div>
              <div class="info-text2">{{ $t('home.button2Title1') }}</div>
              <div class="info-text3">{{ $t('home.button2Title2') }}</div>
              <div class="info-button" @click="$router.push('/loan')">{{ $t('home.GetStarted') }}</div>
            </div>
            <div class="masking"></div>
          </div>
          <div class="info-buttons info-buttons1">
            <div class="info-buttons-content">
              <div class="null-height"></div>
              <div class="icon"><img src="@/assets/img/icon_charity.ce0fa0ca.svg" alt=""></div>
              <div class="info-text1">{{ $t('home.button4Title') }}</div>
              <div class="info-text2">{{ $t('home.button4Title1') }}</div>
              <div class="info-text3 info-text31">{{ $t('home.button4Title2') }}</div>
              <div class="info-button" 
              @click="$router.push('/nfts')"
              >{{ $t('home.GetStarted') }}</div>
            </div>
            <div class="masking"></div>
          </div>
          <div class="info-policy">
            <div class="info_item">
              <div class="info_title">{{ $t('navBottom.bottomCompany') }}</div>
              <div class="info_list">
                <div class="item_name" v-for="(item, index) in company" @click="toDetailText(item)" :key="index">{{ item.title}}</div>
                <!-- <div class="item_name">{{ $t('navBottom.bottomCompanyItem2') }}</div>
                <div class="item_name">{{ $t('navBottom.bottomCompanyItem3') }}</div> -->
              </div>
            </div>
            <div class="info_item">
              <div class="info_title">{{ $t('navBottom.bottomPolicy') }}</div>
              <div class="info_list">
                <div class="item_name" v-for="(item, index) in policy" @click="toDetailText(item)" :key="index">{{ item.title }}</div>
                <!-- <div class="item_name">{{ $t('navBottom.bottomPolicyItem2') }}</div> -->
              </div>
            </div>
            <div class="info_item">
              <div class="info_title">{{ $t('navBottom.bottomOther') }}</div>
              <div class="info_list">
                <div class="item_name"  v-for="(item, index) in footer" @click="toDetailText(item)" :key="index">{{ item.title }}</div>
                <!-- <div class="item_name">{{ $t('navBottom.bottomOtherItem2') }}</div>
                <div class="item_name">{{ $t('navBottom.bottomOtherItem3') }}</div>
                <div class="item_name">{{ $t('navBottom.bottomOtherItem5') }}</div> -->
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</template>
<script>
import { randomLine } from "@/utils/tools.js";
import { mapGetters } from "vuex";
import { mapState } from "vuex";
import { listMarketByType } from '@/api/market.js'
import NavM from "@/components/nav_m.vue";
export default {
  components: {
    NavM
  },
  data() {
    return {
      marketList: [],
      chartData: {},
        // 公司
        company: [],
      policy: [],
      footer: []
    }
  },
  computed: {
    ...mapGetters(["marketWsList","getContentList"]),
    ...mapState(["isPc"]),
  },

  watch: {
      getContentList: {
        handler(val) {
          console.log(val);
          this.company=val.filter((item) => item.type==1);
          this.policy=val.filter((item) => item.type==2);
          this.footer=val.filter((item) => item.type==3);
        },
        immediate: true,
        deep: true,
      },
    marketWsList: {
      handler: function (val, oldVal) {
        if (val[0]) {
          const data=val;
          this.marketList.forEach(item => {
            data.forEach(item1 => {
              if (item1.code===item.code) {
                item.nowPrice=item1.price;
                item.zdf=item1.zdf;
              }
            });
          });


          data.forEach(item => {
            if (!this.chartData[item.code]) {
              this.chartData[item.code]=[];
            }
            this.chartData[item.code].push(item.price);
            this.chartInit(item.code);
          });
        }
      },
      deep: true
    }
  },


  mounted() {
    this.getListMarketByType()
  },
  methods: {
    toDetailText(item) {
      // 判断跳转的路由是否是当前路由
      if (this.$route.query.id == item.id) {
        return;
      }
      this.$router.push({ path: "/detail", query: { id: item.id, title: item.title } });
    },
    toDetail(item) {
      this.$router.push({ path: '/cruuency', query: { code: item.code, type: item.type, id: item.id } })
    },
    chartInit(code, hundred=[]) {
      // 基于准备好的dom，初始化echarts实例
      let dom=document.getElementById(code);
      if (!dom) {
        return;
      }
      this.$echarts.dispose(document.getElementById(code));
      const myChart=this.$echarts.init(document.getElementById(code));

      // 指定图表的配置项和数据


      var timeData=[];
      var valueData=[];
      // // 控制数据长度，只保留最近的 N 个数据，可以根据需要调整
      var maxLength=100;
      if (hundred.length===0) {
        timeData=this.chartData[code].map((item, index) => index);
        valueData=this.chartData[code].map(item => item).filter(item => item!=0);
        // 超过最大长度时就删掉前面的
        if (timeData.length>maxLength) {
          let num=timeData.length-maxLength;
          for (let i=0; i<num; i++) {
            timeData.shift();
            valueData.shift();
          }
        }


      } else {
        hundred.forEach(item => {
          timeData.push(item.time);
          valueData.push(item.price);
        })
        if (timeData.length>maxLength) {
          timeData.shift();
          valueData.shift();
        }
      }
      // console.log(timeData);
      // console.log(valueData);
      if(!valueData[0]){
        valueData = randomLine();
      }
      let red='#ff2828';
      let green='#44b400';

      var option={
        xAxis: {
          type: 'category',
          data: timeData,
          show: false,
        },
        grid: {
          left: '10%',
          right: '10%',
          top: '10%',
          bottom: '10%',
        },
        yAxis: {
          type: 'value',
          show: false,
          // 取数据的最大值和最小值
          max: function (value) {
            return Math.max.apply(null, valueData);
          },
          min: function (value) {
            return Math.min.apply(null, valueData);
          },
        },
        series: [
          {
            type: 'line',
            data: valueData,
            symbol: 'none',
            animation: false, // 取消折线的动画
            itemStyle: {
              color: this.marketList.find(item => item.code===code).zdf>0? red:green
            },
            lineStyle: {
              width: 1, // 设置折线的线条宽度
            },

          },
        ],

      };
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);

    },
    getListMarketByType() {
      listMarketByType().then(res => {
        console.log(res)
        this.marketList=res.result
        this.marketList.forEach((item, index) => {
          this.marketList[index].nowPrice=item.price;
          this.chartData[item.code]=item.hundred;
          setTimeout(() => {
            this.chartInit(item.code);
          })
        })


      }).catch(err => {
        console.log(err)
      })
    }
  }

}
</script>
<style scoped>
.home {
  /* width: 14.4rem; */
  overflow: hidden;
  padding-top: 0.75rem;
  padding-bottom: 1.04rem;
  margin: 0 auto;
  overflow-x: hidden;
}

.home-header {
  width: 12rem;
  margin: auto;
  position: relative;
}

.home-header .home-header-title {
  width: 7.27rem;
  /* height: 2.09rem; */
  color: #000;
  font-size: 0.67rem;
  line-height: 0.7rem;
  font-family: Mabry Pro;
  font-weight: 600;
}

.home-header .home-header-app {
  width: 2.05rem;
  height: 0.82rem;
  border-radius: 0.16rem;
  padding: 0.18rem 0.24rem;
  background: #000;
  margin-top: 0.36rem;
  /* margin-top: 0.86rem; */
  position: relative;
  font-family: Mabry Pro;
}

.home-header .home-header-app .app-icon {
  position: absolute;
  width: 0.23rem;
  height: 0.28rem;
  left: 0.25rem;
  top: 0.24rem;
}

.home-header .home-header-app .app-right {
  position: absolute;
  left: 0.61rem;
}

.home-header .home-header-app .app-right .app-ava {
  font-weight: 400;
  font-size: 0.14rem;
  line-height: 0.14rem;
  color: hsla(0, 0%, 100%, 0.7);
}

.home-header .home-header-app .app-right .app-store {
  font-weight: 700;
  font-size: 0.24rem;
  line-height: 0.24rem;
  color: #fff;
}

.home-header .home-header-application,
.home-header .home-header-credit {
  font-family: Mabry Pro;
  margin-top: 0.75rem;
}

.home-header .home-header-application .icon-title,
.home-header .home-header-credit .icon-title {
  height: 0.24rem;
  display: flex;
  font-weight: 700;
  font-size: 0.24rem;
  color: #000;
  margin-bottom: 0.16rem;
}

.home-header .home-header-application .icon-title img,
.home-header .home-header-credit .icon-title img {
  width: 0.22rem;
  height: 0.24rem;
  transform: rotate(90deg);
  margin-top: 0.12rem;
  position: relative;
  top: -0.12rem;
}

.home-header .home-header-application .icon-title span,
.home-header .home-header-credit .icon-title span {
  margin-left: 0.12rem;
  font-family: Mabry Pro;
}

.home-header .home-header-application .icon-content,
.home-header .home-header-credit .icon-content {
  font-family: Mabry Pro;
  width: 4.1rem;
  height: 0.38rem;
  font-weight: 400;
  font-size: 0.15rem;
  line-height: 0.22rem;
  position: relative;
  left: 0.34rem;
}

.home-header .home-header-credit {
  margin-top: 0.46rem;
}

.home-header .home-header-box {
  /* width: 10.22rem; */
  /* height: 1.22rem; */
  background: #fff;
  box-shadow: 0 0.56rem 0.54rem rgba(0, 0, 0, 0.04);
  border-radius: 0.14rem;
  margin-top: 1.48rem;
  display: flex;
  align-items: center;
  padding: 0.68rem 0.89rem;
  z-index: 1;
}

.home-header .home-header-box .box-mill,
.home-header .home-header-box .box-money,
.home-header .home-header-box .box-times {
  height: 1.22rem;
  width: 3.49rem;
}

.home-header .home-header-box .box-mill .box-top,
.home-header .home-header-box .box-money .box-top,
.home-header .home-header-box .box-times .box-top {
  height: 0.52rem;
  font-weight: 500;
  font-size: 0.52rem;
  line-height: 100%;
  color: #000;
  font-family: Mabry Pro;
  margin-bottom: 0.18rem;
}

.home-header .home-header-box .box-mill .box-content,
.home-header .home-header-box .box-money .box-content,
.home-header .home-header-box .box-times .box-content {
  height: 0.52rem;
  font-weight: 400;
  font-size: 0.22rem;
  line-height: 0.3rem;
  font-family: Mabry Pro;
  color: rgba(0, 0, 0, 0.6);
}

.home-header .home-header-box .box-times {
  width: 2.36rem;
  margin-left: 0.94rem;
  margin-right: 1.01rem;
}

.home-header .home-header-box .box-mill {
  width: 2.43rem;
}

.home-header .home-header-imgs {
  position: absolute;
  right: 0.89rem;
  top: -0.48rem;
  width: 5.34rem;
  height: 8.82rem;
}

.home-header .home-header-imgs .imgs-left {
  position: absolute;
  width: 2.74rem;
  height: 5.59rem;
  left: -0.3rem;
  top: 2.6rem;
  filter: drop-shadow(-0.15rem 1.04rem 0.4rem rgba(0, 0, 0, 0.08));
  z-index: -1;
}

.home-header .home-header-imgs .imgs-right {
  position: absolute;
  width: 3.32rem;
  height: 8.82rem;
  right: 0;
  filter: drop-shadow(-0.15rem 1.04rem 0.4rem rgba(0, 0, 0, 0.08));
  background-size: cover;
  z-index: -1;
}

.home-body {
  width: 13.2rem;
  margin: auto;
  margin-top: 1rem;
}

.home-body .box-butttons1 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
}

.home-body .box-butttons1 .buttons-big {
  padding: 0.34rem 0;
  width: 6.51rem;
  border-radius: 0.16rem;
  background: #000;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.home-body .box-butttons1 .buttons-big .info-buttons-content {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.home-body .box-butttons1 .buttons-big .info-buttons-content .content-icon {
  width: 0.28rem;
  height: 0.28rem;
  background: url(../../../assets/img/icon_vetor.e4607d5d.svg) no-repeat;
  border-radius: 0.28rem;
  margin: 0 auto;
}

.home-body .box-butttons1 .buttons-big .info-buttons-content .content-text {
  width: 100%;
  height: 0.24rem;
  font-family: Mabry Pro;
  font-style: normal;
  font-weight: 700;
  font-size: 0.24rem;
  line-height: 0.24rem;
  color: #fff;
  margin-top: 0.16rem;
  margin-bottom: 0.12rem;
  text-align: center;
}

.home-body .box-butttons1 .buttons-big .info-buttons-content .content-text1 {
  padding: 0 0.3rem;
  box-sizing: border-box;
  width: 100%;
  line-height: 0.2rem;
  font-family: Mabry Pro;
  font-style: normal;
  font-weight: 300;
  font-size: 0.16rem;
  color: hsla(0, 0%, 100%, 0.6);
  text-align: center;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.home-body .box-butttons1 .buttons-big2:before {
  content: "";
  width: 1.1rem;
  height: 1.1rem;
  background: linear-gradient(180deg, transparent, #9ee970);
  transform: rotate(-90deg);
  display: inline-block;
  border-radius: 0.55rem;
  position: absolute;
  right: -0.35rem;
  top: -0.7rem;
}

.home-body .box-butttons1 .buttons-big2:after {
  content: "";
  width: 1.1rem;
  height: 1.1rem;
  background: linear-gradient(180deg, transparent, #a5a6f5);
  transform: rotate(-30deg);
  display: inline-block;
  border-radius: 0.55rem;
  position: absolute;
  left: 1.2rem;
  bottom: -0.7rem;
}

.home-body .box-butttons1 .buttons-big1 {
  background: unset;
  border: 0.01rem solid #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home-body .box-butttons1 .buttons-big1 .content-icon {
  width: 0.42rem;
  height: 0.42rem;
  background: url(../../../assets/img/icon_bell1.98a9a1d5.svg) no-repeat;
  background-size: cover;
}

.home-body .box-butttons1 .buttons-big1 .content-text {
  width: 80%;
  height: 0.5rem;
  font-weight: 700;
  font-size: 0.22rem;
  color: #000;
  margin-top: 0.15rem;
  text-align: center;
}

.home-body .box-butttons2 {
  margin-top: 0.18rem;
  width: 100%;
  height: 2.68rem;
  display: flex;
  justify-content: space-between;
}

.home-body .box-butttons2 .butttons2 {
  width: 3.18rem;
  height: 2.68rem;
  border-radius: 0.1rem;
  background: #000 no-repeat;
  background-size: cover;
  position: relative;
  overflow: hidden;
}

.home-body .box-butttons2 .butttons2 .info-buttons-content {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 2;
}

.home-body .box-butttons2 .butttons2 .info-buttons-content .butttons2-icon {
  width: 0.46rem;
  height: 0.46rem;
  text-align: center;
  margin: auto;
  margin-top: 0.54rem;
}

.home-body .box-butttons2 .butttons2 .info-buttons-content .butttons2-icon1 {
  margin-top: 0.35rem;
}

.home-body .box-butttons2 .butttons2 .info-buttons-content .butttons2-text1 {
  width: 100%;
  font-weight: 300;
  font-size: 0.12rem;
  line-height: 100%;
  text-align: center;
  color: hsla(0, 0%, 100%, 0.6);
  margin-top: 0.17rem;
  margin-bottom: 0.12rem;
  font-family: Mabry Pro;
}

.home-body .box-butttons2 .butttons2 .info-buttons-content .butttons2-text2 {
  width: 100%;
  font-weight: 700;
  font-size: 0.2rem;
  line-height: 100%;
  text-align: center;
  color: #fff;
  margin-bottom: 0.12rem;
  font-family: Mabry Pro;
}

.home-body .box-butttons2 .butttons2 .info-buttons-content .butttons2-text3 {
  width: 100%;
  font-weight: 300;
  font-size: 0.12rem;
  line-height: 0.2rem;
  text-align: center;
  color: hsla(0, 0%, 100%, 0.6);
  margin-bottom: 0.12rem;
  font-family: Mabry Pro;
}

.home-body .box-butttons2 .butttons2 .info-buttons-content .butttons2-button {
  font-family: Mabry Pro;
  width: 1.14rem;
  height: 0.34rem;
  background: #fff;
  border-radius: 0.22rem;
  font-weight: 500;
  font-size: 0.14rem;
  line-height: 100%;
  text-align: center;
  line-height: 0.34rem;
  margin: auto;
}

.home-body .box-butttons2 .butttons2:before {
  content: "";
  width: 1.1rem;
  height: 1.1rem;
  background: linear-gradient(180deg, transparent, #a5a6f5);
  display: inline-block;
  border-radius: 0.55rem;
  position: absolute;
  left: -0.2rem;
  bottom: -0.4rem;
}

.home-body .box-butttons2 .butttons2:after {
  content: "";
  width: 1.1rem;
  height: 1.1rem;
  background: linear-gradient(180deg, transparent, #9ee970);
  transform: rotate(-180deg);
  display: inline-block;
  border-radius: 0.55rem;
  position: absolute;
  right: -0.2rem;
  top: -0.5rem;
}

.box-lists {
  width: 9.86rem;
  margin: auto;
  margin-top: 1.3rem;
  margin-bottom: 1.3rem
}

.box-lists .box-heder {
  width: 100%;
  font-family: Mabry Pro;
  font-style: normal;
  font-weight: 400;
  font-size: .18rem;
  line-height: 100%;
  color: rgba(0, 0, 0, .5);
  height: .38rem;
  display: flex;
  justify-content: space-between
}

.box-lists .box-heder div {
  float: left
}

.box-lists .box-heder .box-heder1 {
  margin-left: .44rem;
  text-align: left;
  width: 2.47rem
}

.box-lists .box-heder .box-heder2 {
  text-align: right;
  width: 1.4rem
}

.box-lists .box-heder .box-heder3 {
  width: 1.8rem;
  text-align: right;
  margin-right: .6rem
}

.box-lists .box-heder .box-heder4 {
  width: 1.19rem;
  text-align: right;
  margin-right: .6rem
}

.box-lists .box-heder .box-heder4-1 {
  margin-right: 0
}

.box-lists .box-heder .box-heder5 {
  width: 1.21rem;
  text-align: right
}

.box-lists .box-body .box-list {
  width: 100%;
  height: .84rem;
  background: #fff;
  box-shadow: 0 .34rem .44rem rgba(0, 0, 0, .08);
  border-radius: .16rem;
  margin-bottom: .16rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  cursor: pointer
}

.box-lists .box-body .box-list div {
  float: left
}

.box-lists .box-body .box-list .box-heder1 {
  margin-left: .44rem;
  text-align: left;
  width: 2.47rem;
  line-height: .84rem;
  font-family: Mabry Pro;
  font-style: normal;
  font-weight: 700
}

.box-lists .box-body .box-list .box-heder1 img {
  height: .36rem;
  vertical-align: middle;
  margin-right: .1rem
}

.box-lists .box-body .box-list .box-heder2 {
  text-align: right;
  width: 1.14rem;
  font-family: Mabry Pro;
  font-style: normal;
  font-weight: 500;
  color: #44b400;
  line-height: .84rem
}

.box-lists .box-body .box-list .box-heder2-1 {
  color: #ff2828
}

.box-lists .box-body .box-list .box-heder3 {
  width: 1.8rem;
  text-align: right;
  font-family: Mabry Pro;
  font-style: normal;
  font-weight: 400;
  line-height: .7rem;
  color: rgba(0, 0, 0, .5);
  display: flex;
  align-items: center
}

.box-lists .box-body .box-list .box-heder3 .chart {
  width: 1.8rem;
  height: .6rem
}

.box-lists .box-body .box-list .box-heder3 img {
  width: 1.1rem;
  height: .32rem
}

.box-lists .box-body .box-list .box-heder4 {
  width: 1.19rem;
  text-align: right;
  margin-right: .6rem;
  font-family: Mabry Pro;
  font-style: normal;
  font-weight: 400;
  line-height: .84rem;
  color: rgba(0, 0, 0, .5)
}

.box-lists .box-body .box-list .box-heder4-1 {
  margin-right: 0
}

.box-lists .box-body .box-list .box-heder5 {
  width: 1.21rem;
  text-align: right;
  font-family: Mabry Pro;
  font-style: normal;
  font-weight: 400;
  line-height: .84rem;
  color: rgba(0, 0, 0, .5)
}

.box-lists .box-body .box-list .box-heder5-1 {
  width: 1.21rem
}

.box-lists .box-body .box-list .box-heder5-1 .add-button {
  display: inline-block;
  width: .63rem;
  height: .74rem;
  background: #efefef;
  border-radius: .16rem;
  font-weight: 500;
  font-size: .26rem;
  color: #000;
  text-align: center;
  line-height: .74rem;
  margin-top: .05rem;
  float: right;
  cursor: pointer
}

.box-lists .box-body .box-list-no {
  cursor: auto
}

.box-lists .box-body .box-list1 {
  z-index: 3
}

.box-lists .box-body .box-list2 {
  z-index: 2
}

.box-lists .box-body .box-list3 {
  z-index: 1
}
.home-mobile {
    padding-top: .9rem;
    width: 100%
}

.home-mobile .content {
    width: calc(100% - .48rem);
    margin: auto
}

.home-mobile .content .new-feature {
    width: 100%;
    background: #000;
    border-radius: .2rem;
    padding: .26rem;
    position: relative;
    overflow: hidden
}

.home-mobile .content .new-feature .new-feature-content {
    position: relative;
    z-index: 2
}

.home-mobile .content .new-feature .new-feature-content .feature-icon {
    width: .28rem;
    height: .28rem;
    margin-bottom: .16rem
}

.home-mobile .content .new-feature .new-feature-content .feature-title {
    font-family: Mabry Pro;
    font-style: normal;
    font-weight: 500;
    font-size: .22rem;
    color: #fff;
    margin-bottom: .12rem
}

.home-mobile .content .new-feature .new-feature-content .feature-content {
    font-family: Mabry Pro;
    font-style: normal;
    font-size: .14rem;
    color: hsla(0,0%,100%,.6);
    line-height: .2rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow: hidden
}

.home-mobile .content .new-feature:before {
    content: "";
    width: 1.1rem;
    height: 1.1rem;
    background: linear-gradient(180deg,transparent,#9ee970);
    transform: rotate(-90deg);
    display: inline-block;
    border-radius: .55rem;
    position: absolute;
    right: -.35rem;
    top: -.7rem
}

.home-mobile .content .new-feature:after {
    content: "";
    width: 1.1rem;
    height: 1.1rem;
    background: linear-gradient(180deg,transparent,#a5a6f5);
    transform: rotate(-30deg);
    display: inline-block;
    border-radius: .55rem;
    position: absolute;
    left: 1.2rem;
    bottom: -.7rem
}

.home-mobile .content .wallet {
    width: 100%;
    height: .5rem;
    background: #fff;
    border: .01rem solid #000;
    border-radius: .16rem;
    font-weight: 700;
    font-size: .16rem;
    color: #000;
    margin-top: .12rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center
}

.home-mobile .content .wallet img {
    width: .28rem;
    height: .28rem;
    vertical-align: middle
}

.home-mobile .content .wallet .msg-content {
    max-width: 80%
}

.home-mobile .content .info-buttons {
    width: 100%;
    height: max-content;
    background: #000;
    margin-top: .12rem;
    border-radius: .2rem;
    font-family: Mabry Pro;
    font-style: normal;
    text-align: center;
    position: relative;
    overflow: hidden
}

.home-mobile .content .info-buttons .info-buttons-content {
    position: relative;
    z-index: 2;
    width: 100%;
    height: max-content
}

.home-mobile .content .info-buttons .info-buttons-content .null-height {
    width: 100%;
    height: .4rem
}

.home-mobile .content .info-buttons .info-buttons-content .icon {
    width: .4rem;
    height: .4rem;
    margin: auto
}

.home-mobile .content .info-buttons .info-buttons-content .info-text1 {
    width: 100%;
    font-weight: 300;
    font-size: .14rem;
    color: hsla(0,0%,100%,.6);
    margin-top: .14rem
}

.home-mobile .content .info-buttons .info-buttons-content .info-text2 {
    width: 100%;
    font-weight: 500;
    font-size: .22rem;
    color: #fff;
    margin-top: .14rem
}

.home-mobile .content .info-buttons .info-buttons-content .info-text3 {
    width: 100%;
    font-weight: 300;
    font-size: .14rem;
    color: hsla(0,0%,100%,.6);
    line-height: .2rem;
    margin-top: .14rem
}

.home-mobile .content .info-buttons .info-buttons-content .info-text31 {
    width: 3.23rem;
    margin: auto;
    margin-top: .14rem
}

.home-mobile .content .info-buttons .info-buttons-content .info-button {
    width: 1.25rem;
    height: .44rem;
    background: #fff;
    border-radius: .22rem;
    font-weight: 500;
    font-size: .16rem;
    color: #000;
    line-height: .44rem;
    margin: auto;
    margin-top: .14rem;
    margin-bottom: .34rem
}

.home-mobile .content .info-buttons:before {
    content: "";
    width: 1.1rem;
    height: 1.1rem;
    background: linear-gradient(180deg,transparent,#a5a6f5);
    transform: rotate(-100deg);
    display: inline-block;
    border-radius: .55rem;
    position: absolute;
    left: calc(100% - 1.1rem);
    bottom: -.4rem
}

.home-mobile .content .info-buttons:after {
    content: "";
    width: 1.1rem;
    height: 1.1rem;
    background: linear-gradient(180deg,transparent,#9ee970);
    transform: rotate(-180deg);
    display: inline-block;
    border-radius: .55rem;
    position: absolute;
    left: -.5rem;
    top: -.5rem
}

.home-mobile .content .info-policy {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: .4rem
}

.home-mobile .content .info-policy .info_item {
    margin-top: .6rem;
    width: 50%;
    padding-left: .3rem;
    box-sizing: border-box
}

.home-mobile .content .info-policy .info_item .info_title {
    margin-bottom: .3rem;
    font-weight: 700;
    font-size: .18rem
}

.home-mobile .content .info-policy .info_item .info_list .item_name {
    margin-top: .22rem;
    font-size: .16rem;
    color: rgba(0,0,0,.6)
}

.home-mobile .masking {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    background-image: url(../../../assets/img/noise.90f9bf5b.png);
    z-index: 1
}

</style>