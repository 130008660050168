<template>
  <div>
    <div class="home home-mobile">
      <div class="content">
        <div class="new-feature">
          <div class="new-feature-content">
            <img
              src="@/assets/img/icon_vetor.e4607d5d.svg"
              alt=""
              class="feature-icon"
              srcset=""
            />
            <div class="feature-title">新功能上线！</div>
            <div class="feature-content">
              <p>
                欢迎使用我们屡获殊荣的金融交易平台，与全球投资者一起探索未来的金融世界！
              </p>
            </div>
          </div>
          <div class="masking"></div>
        </div>
        <div class="wallet">
          <img
            src="@/assets/img/icon_bell1.98a9a1d5.svg"
            alt=""
            srcset=""
          />
          <div>用模拟账户操作</div>
        </div>
        <div class="info-buttons">
          <div class="info-buttons-content">
            <div class="null-height"></div>
            <img
              src="@/assets/img/icon_place.201127c8.svg"
              alt=""
              class="icon"
            />
            <div class="info-text1">最低交易费用 &lt; 0.3%</div>
            <div class="info-text2">在一个地方交易所有资产</div>
            <div class="info-text3">外汇、加密货币、股票、ETF、期货</div>
            <div class="info-button">开始</div>
          </div>
          <div class="masking"></div>
        </div>
        <div class="info-buttons">
          <div class="info-buttons-content">
            <div class="null-height"></div>
            <img
              src="@/assets/img/icon_trading.733e50e8.svg"
              alt=""
              class="icon"
            />
            <div class="info-text1">简单稳定</div>
            <div class="info-text2">智能交易</div>
            <div class="info-text3">一键创建您的盈利计划</div>
            <div class="info-button">开始</div>
          </div>
          <div class="masking"></div>
        </div>
        <div class="info-buttons">
          <div class="info-buttons-content">
            <div class="null-height"></div>
            <img
              src="@/assets/img/icon_loans.dd791035.svg"
              alt=""
              class="icon"
            />
            <div class="info-text1">低利率和更高额度</div>
            <div class="info-text2">投资借款</div>
            <div class="info-text3">
              无需抵押品，轻松借款，享受财富增值机会！!
            </div>
            <div class="info-button">开始</div>
          </div>
          <div class="masking"></div>
        </div>
        <div class="info-buttons info-buttons1">
          <div class="info-buttons-content">
            <div class="null-height"></div>
            <img
              src="@/assets/img/icon_charity.ce0fa0ca.svg"
              alt=""
              class="icon"
            />
            <div class="info-text1">帮助世界各地的儿童</div>
            <div class="info-text2">NFT慈善</div>
            <div class="info-text3 info-text31">
              购买NFT艺术品以支持联合国儿童基金会
            </div>
            <div class="info-button">开始</div>
          </div>
          <div class="masking"></div>
        </div>
        <div class="info-policy">
          <div class="info_item">
            <div class="info_title">公司</div>
            <div class="info_list">
              <div class="item_name">关于我们</div>
              <div class="item_name">牌照监管</div>
              <div class="item_name">常见问题</div>
            </div>
          </div>
          <div class="info_item">
            <div class="info_title">政策</div>
            <div class="info_list">
              <div class="item_name">条款条件</div>
              <div class="item_name">隐私政策</div>
            </div>
          </div>
          <div class="info_item">
            <div class="info_title">声明</div>
            <div class="info_list">
              <div class="item_name">法律信息</div>
              <div class="item_name">免责声明</div>
              <div class="item_name">风险提示</div>
              <div class="item_name">关于广告</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  mounted() {},
  methods: {
    gopage(url) {
      this.$router.push(url);
    },
  },
};
</script>
  <style scoped>
.home-mobile {
  padding-top: 0.9rem;
  width: 100%;
}

.home-mobile .content {
  width: calc(100% - 0.48rem);
  margin: auto;
}

.home-mobile .content .new-feature {
  /* width: calc(100% - 0.52rem); */
  background: #000;
  border-radius: 0.2rem;
  padding: 0.26rem;
  position: relative;
  overflow: hidden;
}

.home-mobile .content .new-feature .new-feature-content {
  position: relative;
  z-index: 2;
}

.home-mobile .content .new-feature .new-feature-content .feature-icon {
  width: 0.28rem;
  height: 0.28rem;
  margin-bottom: 0.16rem;
}

.home-mobile .content .new-feature .new-feature-content .feature-title {
  font-family: Mabry Pro;
  font-style: normal;
  font-weight: 500;
  font-size: 0.22rem;
  color: #fff;
  margin-bottom: 0.12rem;
}

.home-mobile .content .new-feature .new-feature-content .feature-content {
  font-family: Mabry Pro;
  font-style: normal;
  font-size: 0.14rem;
  color: hsla(0, 0%, 100%, 0.6);
  line-height: 0.2rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.home-mobile .content .new-feature:before {
  content: "";
  width: 1.1rem;
  height: 1.1rem;
  background: linear-gradient(180deg, transparent, #9ee970);
  transform: rotate(-90deg);
  display: inline-block;
  border-radius: 0.55rem;
  position: absolute;
  right: -0.35rem;
  top: -0.7rem;
}

.home-mobile .content .new-feature:after {
  content: "";
  width: 1.1rem;
  height: 1.1rem;
  background: linear-gradient(180deg, transparent, #a5a6f5);
  transform: rotate(-30deg);
  display: inline-block;
  border-radius: 0.55rem;
  position: absolute;
  left: 1.2rem;
  bottom: -0.7rem;
}

.home-mobile .content .wallet {
  width: 100%;
  height: 0.5rem;
  background: #fff;
  border: 0.01rem solid #000;
  border-radius: 0.16rem;
  font-weight: 700;
  font-size: 0.16rem;
  color: #000;
  margin-top: 0.12rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-mobile .content .wallet img {
  width: 0.28rem;
  height: 0.28rem;
  vertical-align: middle;
}

.home-mobile .content .wallet .msg-content {
  max-width: 80%;
}

.home-mobile .content .info-buttons {
  width: 100%;
  height: max-content;
  background: #000;
  margin-top: 0.12rem;
  border-radius: 0.2rem;
  font-family: Mabry Pro;
  font-style: normal;
  text-align: center;
  position: relative;
  overflow: hidden;
}

.home-mobile .content .info-buttons .info-buttons-content {
  position: relative;
  z-index: 2;
  width: 100%;
  height: max-content;
}

.home-mobile .content .info-buttons .info-buttons-content .null-height {
  width: 100%;
  height: 0.4rem;
}

.home-mobile .content .info-buttons .info-buttons-content .icon {
  width: 0.4rem;
  height: 0.4rem;
  margin: auto;
}

.home-mobile .content .info-buttons .info-buttons-content .info-text1 {
  width: 100%;
  font-weight: 300;
  font-size: 0.14rem;
  color: hsla(0, 0%, 100%, 0.6);
  margin-top: 0.14rem;
}

.home-mobile .content .info-buttons .info-buttons-content .info-text2 {
  width: 100%;
  font-weight: 500;
  font-size: 0.22rem;
  color: #fff;
  margin-top: 0.14rem;
}

.home-mobile .content .info-buttons .info-buttons-content .info-text3 {
  width: 100%;
  font-weight: 300;
  font-size: 0.14rem;
  color: hsla(0, 0%, 100%, 0.6);
  line-height: 0.2rem;
  margin-top: 0.14rem;
}

.home-mobile .content .info-buttons .info-buttons-content .info-text31 {
  width: 3.23rem;
  margin: auto;
  margin-top: 0.14rem;
}

.home-mobile .content .info-buttons .info-buttons-content .info-button {
  width: 1.25rem;
  height: 0.44rem;
  background: #fff;
  border-radius: 0.22rem;
  font-weight: 500;
  font-size: 0.16rem;
  color: #000;
  line-height: 0.44rem;
  margin: auto;
  margin-top: 0.14rem;
  margin-bottom: 0.34rem;
}

.home-mobile .content .info-buttons:before {
  content: "";
  width: 1.1rem;
  height: 1.1rem;
  background: linear-gradient(180deg, transparent, #a5a6f5);
  transform: rotate(-100deg);
  display: inline-block;
  border-radius: 0.55rem;
  position: absolute;
  left: calc(100% - 1.1rem);
  bottom: -0.4rem;
}

.home-mobile .content .info-buttons:after {
  content: "";
  width: 1.1rem;
  height: 1.1rem;
  background: linear-gradient(180deg, transparent, #9ee970);
  transform: rotate(-180deg);
  display: inline-block;
  border-radius: 0.55rem;
  position: absolute;
  left: -0.5rem;
  top: -0.5rem;
}

.home-mobile .content .info-policy {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 0.4rem;
}

.home-mobile .content .info-policy .info_item {
  margin-top: 0.6rem;
  width: 50%;
  padding-left: 0.3rem;
  box-sizing: border-box;
}

.home-mobile .content .info-policy .info_item .info_title {
  margin-bottom: 0.3rem;
  font-weight: 700;
  font-size: 0.18rem;
}

.home-mobile .content .info-policy .info_item .info_list .item_name {
  margin-top: 0.22rem;
  font-size: 0.16rem;
  color: rgba(0, 0, 0, 0.6);
}

.masking {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-image: url(../../../assets/img/noise.90f9bf5b.png);
  z-index: 1;
}
</style>