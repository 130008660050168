<template>
  <div>
    <div class="board">
      <nav class="nav_mobile">
        <div class="nav_content">
          <div class="logo"><img src="@/assets/img/sax_logo.png" alt="">
            <div class="nav_name">Galaxy Digital</div>
          </div>
          <div class="line"></div>
          <div class="sign_content">
            <div class="sign_item"></div>
            <div class="sign_item active"></div>
          </div>
        </div>
      </nav>
      <div class="img_content"><img src="@/assets/img/img_board.0944cf42.svg" alt="">
      </div>
      <div class="intro_content">
        <div class="title">{{ $t('board.title') }}</div>
        <div class="content">{{ $t('board.content') }}</div>
      </div>
      <div class="actions_content">
        <div class="action_item"  @click="continuePage">
          <div class="button black" style="padding: 0px 36px;"><span style="font-size: 20px;">{{
            $t('board.continue') }}</span></div>
        </div>
        <div class="action_item" @click="skip">
          <div class="button line" style="padding: 0px 36px;"><span style="font-size: 20px;">{{
            $t('board.skip') }}</span></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: 'Board',
  data() {
    return {
      show: true
    }
  },
  computed: {
    ...mapState(['isLogin','showBoard']),
    currentPath() {
      return this.$route.path
    }
  },
  methods: {
    continuePage() {
      this.$store.commit('setShowBoard', 1)
      if (this.isLogin) {
        if (this.currentPath!='/') {
          this.$router.push('/')
        }
      } else {
        if (this.currentPath!='/pass/register') {
          this.$router.push({ path: '/pass/register' })
        }
      }
    },
    skip() {
      this.$store.commit('setShowBoard', 1)
      if (this.isLogin) {
        if (this.currentPath!='/') {
          this.$router.push('/')
        }
      } else {
        if (this.currentPath!='/') {
          this.$router.push('/')
        }
      }
    }
  }
}

</script>
<style scoped lang="scss">
.board {
  padding-bottom: .5rem
}

.board .nav_mobile {
  padding-top: .22rem
}

.board .nav_mobile .nav_content {
  padding: 0 .24rem;
  display: flex;
  justify-content: space-between;
  align-items: center
}

.board .nav_mobile .nav_content .logo {
  height: .36rem;
  display: flex;
  align-items: center;
  width: 167px;
}

.board .nav_mobile .nav_content .logo img {
  height: 100%;
  width: auto
}

.board .nav_mobile .nav_content .logo .nav_name {
  margin-left: .1rem;
  // padding-bottom: .04rem;
  font-weight: 700;
  font-size: .22rem;
  color: #000;
}

.board .nav_mobile .nav_content .line {
  flex: 1;
  margin: 0 .16rem;
  height: .02rem;
  background: #000
}

.board .nav_mobile .nav_content .sign_content {
  display: flex
}

.board .nav_mobile .nav_content .sign_content .sign_item {
  box-sizing: border-box;
  width: .14rem;
  height: .14rem;
  background: #fff;
  border: .02rem solid #000;
  border-radius: 50%
}

.board .nav_mobile .nav_content .sign_content .sign_item:last-child {
  margin-left: .06rem
}

.board .nav_mobile .nav_content .sign_content .sign_item.active {
  background: #000
}

.board .img_content {
  margin-top: .56rem
}

.board .img_content img {
  width: 100%;
  height: auto
}

.board .intro_content {
  margin-top: .78rem;
  padding: 0 .24rem;
  text-align: center;
  color: #000
}

.board .intro_content .title {
  font-weight: 700;
  font-size: .46rem
}

.board .intro_content .content {
  margin-top: .22rem;
  font-weight: 300;
  font-size: .18rem
}

.board .actions_content {
  margin-top: .5rem;
  padding: 0 .24rem;
  display: flex;
  justify-content: space-between
}

.board .actions_content .action_item {
  height: .68rem
}

.board .actions_content .action_item:first-child {
  flex: 1
}

.board .actions_content .action_item:last-child {
  margin-left: .2rem
}
</style>