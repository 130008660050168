<template>
  <div class="nav_mobile">
    <div class="nav_content">
      <div class="nav_item_content">
        <div class="nav_item" @click="gopage(item.path)" v-for="(item, index) in navList" :key="index">
          <img :src="item.activated" v-if="currentPath == item.path" alt="">
          <img :src="item.icon" v-else alt="">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
    };
  },
  mounted() { },
  computed: {
    ...mapState(["footerWhiteList"]),
    navList(){
      return [
        {
          name: this.$t('navTab.tabMenu1'),
          icon: require('../assets/img/icon_nav1.b222da82.svg'),
          activated: require('../assets/img/icon_nav1_active.87a2c8a5.svg'),
          path: '/'
        },
        {
          name: this.$t('navTab.tabMenu2'),
          path: '/market',
          icon: require('../assets/img/icon_nav2.c0b551ec.svg'),
          activated: require('../assets/img/icon_nav2_active.459bde24.svg'),
        },
        {
          name: this.$t('navTab.tabMenu3'),
          path: '/trading',
          icon: require('../assets/img/icon_nav5.5ffb6579.svg'),
          activated: require('../assets/img/icon_nav5_active.c6a813d0.svg'),
        },
        {
          name: this.$t('navTab.tabMenu4'),
          path: '/loan',
          icon: require('../assets/img/icon_nav4.a1957aac.svg'),
          activated: require('../assets/img/icon_nav4_active.14586cbb.svg'),
        },
        {
          name: this.$t('navTab.tabMenu5'),
          path: '/nfts',
          icon: require('../assets/img/icon_nav3.3015d650.svg'),
          activated: require('../assets/img/icon_nav3_active.f301c79b.svg'),
        },
        {
          name: this.$t('navTab.tabMenu6'),
          path: '/profile',
          icon: require('../assets/img/icon_nav6.d13c1773.svg'),
          activated: require('../assets/img/icon_nav6_active.26b75174.svg'),
        },
      ]
    },
    // isShowNav() {
    //   return this.footerWhiteList.includes(this.currentRouteName);
    // },
    // 获取当前路由路径
    currentPath() {
      console.log(this.$route);
      // 获取子路由之前的路径 /profile/balance 取/profile
      let path='/'+this.$route.path.split('/')[1];
      return path
    },
   

  },
  methods: {
    gopage(url) {
      this.$router.push(url);
    },
  },
};
</script>
<style scoped>
.nav_mobile {
  position: fixed;
  bottom: .24rem;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  z-index: 10
}

.nav_mobile .nav_content {
  padding: 0 .24rem;
  width: 100%;
  box-sizing: border-box
}

.nav_mobile .nav_content .nav_item_content
.nav_mobile {
    position: fixed;
    bottom: .24rem;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 10
}

.nav_mobile .nav_content {
    padding: 0 .24rem;
    width: 100%;
    box-sizing: border-box
}

.nav_mobile .nav_content .nav_item_content {
    padding: 0 .21rem;
    height: .68rem;
    border-radius: .2rem;
    background: #000;
    display: flex;
    justify-content: space-between;
    align-items: center
}

.nav_mobile .nav_content .nav_item_content .nav_item {
    width: .28rem;
    height: .28rem
}

.nav_mobile .nav_content .nav_item_content .nav_item img {
    width: 100%;
    height: 100%
}

.nav_bottom {
    padding: .14rem;
    min-width: 11rem
}

.nav_bottom .nav_content {
    padding: .7rem;
    width: 100%;
    box-sizing: border-box;
    border-radius: .14rem;
    background: #000
}

.nav_bottom .nav_content .content_top {
    display: flex;
    justify-content: space-between
}

.nav_bottom .nav_content .content_top .top_left {
    display: flex
}

.nav_bottom .nav_content .content_top .top_left .logo {
    margin-right: 1.27rem;
    height: .32rem;
    display: flex;
    align-items: center
}

.nav_bottom .nav_content .content_top .top_left .logo img {
    height: 100%;
    width: auto
}

.nav_bottom .nav_content .content_top .top_left .logo .nav_name {
    margin-left: .1rem;
    /* padding-bottom: .04rem; */
    font-weight: 700;
    font-size: .22rem;
    color: #fff
}

.nav_bottom .nav_content .content_top .top_left .menu_section {
    margin-left: .97rem
}

.nav_bottom .nav_content .content_top .top_left .menu_section .menu_item {
    margin-top: .2rem;
    font-size: .16rem;
    color: hsla(0,0%,100%,.6)
}

.nav_bottom .nav_content .content_top .top_left .menu_section .menu_item:hover {
    cursor: pointer;
    color: #fff
}

.nav_bottom .nav_content .content_top .top_left .menu_section .menu_item.first {
    cursor: default;
    margin-top: 0;
    margin-bottom: .3rem;
    color: #fff;
    font-size: .18rem
}

.nav_bottom .nav_content .content_top .top_right .icons {
    display: flex
}

.nav_bottom .nav_content .content_top .top_right .icons .icon {
    margin-left: .12rem;
    width: .44rem;
    height: .44rem;
    border-radius: 50%;
    background: hsla(0,0%,100%,.16);
    display: flex;
    justify-content: center;
    align-items: center
}

.nav_bottom .nav_content .content_top .top_right .icons .icon img {
    height: .16rem;
    width: auto
}

.nav_bottom .nav_content .content_bottom {
    margin-top: .7rem;
    display: flex;
    justify-content: space-between;
    font-size: .14rem
}

.nav_bottom .nav_content .content_bottom .bottom_left {
    display: flex;
    color: hsla(0,0%,100%,.6)
}

.nav_bottom .nav_content .content_bottom .bottom_left .link_menu {
    margin-right: .58rem
}

.nav_bottom .nav_content .content_bottom .bottom_left .link_menu:hover {
    cursor: pointer;
    color: #fff
}

.nav_bottom .nav_content .content_bottom .bottom_left .link_menu:last-child {
    margin-right: 0
}

.nav_bottom .nav_content .content_bottom .bottom_right {
    color: #fff
}

.layout[data-v-06f2876c] {
    width: 100%
}


.nav_mobile .nav_content .nav_item_content .nav_item {
  width: .28rem;
  height: .28rem
}

.nav_mobile .nav_content .nav_item_content .nav_item img {
  width: 100%;
  height: 100%
}

.nav_bottom {
  padding: .14rem;
  min-width: 11rem
}

.nav_bottom .nav_content {
  padding: .7rem;
  width: 100%;
  box-sizing: border-box;
  border-radius: .14rem;
  background: #000
}

.nav_bottom .nav_content .content_top {
  display: flex;
  justify-content: space-between
}

.nav_bottom .nav_content .content_top .top_left {
  display: flex
}

.nav_bottom .nav_content .content_top .top_left .logo {
  margin-right: 1.27rem;
  height: .32rem;
  display: flex;
  align-items: center
}

.nav_bottom .nav_content .content_top .top_left .logo img {
  height: 100%;
  width: auto
}

.nav_bottom .nav_content .content_top .top_left .logo .nav_name {
  margin-left: .1rem;
  /* padding-bottom: .04rem; */
  font-weight: 700;
  font-size: .22rem;
  color: #fff
}

.nav_bottom .nav_content .content_top .top_left .menu_section {
  margin-left: .97rem
}

.nav_bottom .nav_content .content_top .top_left .menu_section .menu_item {
  margin-top: .2rem;
  font-size: .16rem;
  color: hsla(0, 0%, 100%, .6)
}

.nav_bottom .nav_content .content_top .top_left .menu_section .menu_item:hover {
  cursor: pointer;
  color: #fff
}

.nav_bottom .nav_content .content_top .top_left .menu_section .menu_item.first {
  cursor: default;
  margin-top: 0;
  margin-bottom: .3rem;
  color: #fff;
  font-size: .18rem
}

.nav_bottom .nav_content .content_top .top_right .icons {
  display: flex
}

.nav_bottom .nav_content .content_top .top_right .icons .icon {
  margin-left: .12rem;
  width: .44rem;
  height: .44rem;
  border-radius: 50%;
  background: hsla(0, 0%, 100%, .16);
  display: flex;
  justify-content: center;
  align-items: center
}

.nav_bottom .nav_content .content_top .top_right .icons .icon img {
  height: .16rem;
  width: auto
}

.nav_bottom .nav_content .content_bottom {
  margin-top: .7rem;
  display: flex;
  justify-content: space-between;
  font-size: .14rem
}

.nav_bottom .nav_content .content_bottom .bottom_left {
  display: flex;
  color: hsla(0, 0%, 100%, .6)
}

.nav_bottom .nav_content .content_bottom .bottom_left .link_menu {
  margin-right: .58rem
}

.nav_bottom .nav_content .content_bottom .bottom_left .link_menu:hover {
  cursor: pointer;
  color: #fff
}

.nav_bottom .nav_content .content_bottom .bottom_left .link_menu:last-child {
  margin-right: 0
}

.nav_bottom .nav_content .content_bottom .bottom_right {
  color: #fff
}

.layout[data-v-06f2876c] {
  width: 100%
}
</style>