<template>
  <div id="app">
    <div v-show="showBoard == 1 || isPc">
      <NavPc v-if="isPc && !is3d"></NavPc>
      <router-view />
      <NavBottomPc v-if="isPc && !is3d" v-show="isShowFooter"></NavBottomPc>
      <TabBar v-if="!isPc && !is3d" v-show="isShowFooter" />
    </div>
    <div v-show="showBoard == 0 && !isPc" >
      <Board />
    </div>
    <!-- <div v-else> -->
      <!-- <NavM></NavM> -->
      <!-- <router-view />
    </div> -->
  </div>
</template>
<script>
import request from "@/utils/request";
import TabBar from "@/components/TabBar.vue";
import NavPc from "@/components/nav_pc.vue";
import NavM from "@/components/nav_m.vue";
import NavBottomPc from "@/components/nav_bottom_pc.vue";
import { mapState } from "vuex";
import store from "./store";
import Board from "./components/Board.vue";
export default {
  data() {
    return {
      windowWidth: document.body.clientWidth,
      // 不显示底部白名单
    };
  },
  components: {
    NavPc,
    NavM,
    NavBottomPc,
    TabBar,
    Board
  },
  mounted() {
    store.commit("SetInnerWidth", document.body.clientWidth);
    store.commit("SET_IS_PC", this.isMobileDevice());
    window.onresize=() => {
      return (() => {
        this.windowWidth=document.documentElement.clientWidth; // 宽
        store.commit("SetInnerWidth", this.windowWidth);
        store.commit("SET_IS_PC", this.isMobileDevice());
      })();
    };
    // 在页面加载时和窗口大小变化时调用设置字体大小的函数
    window.addEventListener('DOMContentLoaded', this.setRootFontSize);
    window.addEventListener('resize', this.setRootFontSize);
    // 设置默认语言
    let lang=localStorage.getItem("lang")||this.$store.state.currentLanguage;
    this.$store.commit("SET_LANG", lang);
    this.$store.dispatch("listContents",lang == 'zh' ? 1 : 2).then((res) => {
      console.log(res, "language");
    });
    // 设置是否虚拟账户
    console.log(localStorage.getItem("virtualStatus"));
    let isVirtual=localStorage.getItem("virtualStatus") || 1;
    this.$store.commit("changeVirtual", isVirtual);
    // ws
    try {
      this.$store.dispatch("GET_WS_MARKET").then((res) => {
        console.log(res, "ws");
      }).catch((err) => {
        console.log(err);
      });
    } catch (error) {
      console.log(error);
    }
    let isLogin=localStorage.getItem("isLogin");
    if (isLogin) {
      this.$store.dispatch("GET_USER_INFO").then((res) => {
        console.log(res, "user");
      });
    }
    

    // 判断当前是手机还是pc
  },
  watch: {
    // 监听页面宽度
    windowWidth(val) {
      console.log(val, "width");
      // store.commit('SetInnerWidth', val)
    },
  },
  computed: {
    ...mapState(["footerWhiteList","isPc","lginWhiteList","showBoard"]),
    currentRouteName() {
      return this.$route.name;
    },
    is3d() {
      return  this.lginWhiteList.indexOf(this.currentRouteName)>-1;
    },
    isShowFooter() {
      return this.footerWhiteList.indexOf(this.currentRouteName)==-1;
    },
  },
  methods: {


    isMobileDevice() {
      var userAgent=navigator.userAgent;
      return !(/Android|iPhone|iPad|iPod/i.test(userAgent));
    },

    setRootFontSize() {
      console.log(111);
      let baseWidth=1920;
      if (window.innerWidth>1000) {
        baseWidth=1920;
      } else {
        baseWidth=750;
      }
      const screenWidth=window.innerWidth||document.documentElement.clientWidth||document.body.clientWidth;
      let scale=1;
      if (baseWidth==750) {
        scale=(screenWidth/baseWidth)+.37;
      } else {
        // scale=(screenWidth/baseWidth) + .2;
        scale=(screenWidth/baseWidth)+1;
      }
      console.log(scale, 'scale');
      const maxRemSize=100;
      const rootFontSize=Math.min(maxRemSize, 100*scale);
      console.log(rootFontSize, 'rootFontSize');
      document.documentElement.style.fontSize=rootFontSize+'px';
    }
  },
};
</script>
<style>
@import "reset.css";

/* @media screen and (min-width: 1000px) {
  #app {
    width: 1440px;
    margin: 0 auto;
  }
} */
body {
  font-size: 0.16rem;
}
img, video {
    max-width: 100%;
    height: auto;
}

.button {
  cursor: pointer;
  padding: 0 .36rem;
  height: 100%;
  border-radius: .2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  font-size: .2rem;
  color: #000;
  -webkit-tap-highlight-color: transparent
}

.button .icon {
  margin-right: .12rem;
  width: .24rem;
  height: .24rem
}

#app {
  margin: 0 auto;
  width: 100%;
  max-width: 14.4rem;
  font-family: Mabry Pro;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  font-size: .16rem;
}

.input_fields .el-input .el-input__wrapper {
  box-shadow: none;
  background: none;
}

.button.round {
  border-radius: .32rem
}

.button.normal {
  background: #e8e8e8
}

.button.yellow {
  background: linear-gradient(180deg, #ffdc61, #ffbc49)
}

.button.black {
  background: #000;
  color: #fff
}

.button.line {
  background: #fff;
  border: .02rem solid #000;
  box-sizing: border-box
}

.button.disabled {
  background: #dfdfdf;
  color: #8a8a8a
}
</style>
